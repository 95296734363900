import React from "react"
import { Container, Header, Icon, Breadcrumb, List, Segment, Card } from "semantic-ui-react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image2 from "../components/image2"

const NotionalMachinesPage = ({ data }) => (
  <Layout>
    <SEO title="Notional Machines" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Notional Machines</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="settings" />
        <Header.Content>
          Notional Machines
          <Header.Subheader>
            Find Misconceptions by Notional Machine
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        A notional machine is a pedagogic device
        to assist the understanding of some aspect of programs or programming.
        Select a notional machine to see misconceptions it can express:
      </p>
      <Card.Group 
          itemsPerRow={2}
          style={{
            marginBottom: "2em",
          }}
        >
        {data.allMdx.nodes.map((node, index) => (
          <Card
            key={index}
            as={Link}
            to={`/notionalMachines/${node.parent.name}/`}>
            <Image2
              className="ui image"
              src={`nm-${node.parent.name}.jpg`}
            />
            <Card.Content>
              <Card.Header>{node.frontmatter.name}</Card.Header>
              <Card.Meta>
                {node.frontmatter.shortDescription}              
              </Card.Meta>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
      <Segment secondary>
        <p>
          To learn more about notional machines,
          read:
        </p>
        <List>
          <List.Item>
            <List.Header><a href="https://dl.acm.org/doi/10.1145/3437800.3439202">Notional Machines in Computing Education: The Education of Attention</a></List.Header>
            ITiCSE-WGR '20: Proceedings of the Working Group Reports on Innovation and Technology in Computer Science Education
          </List.Item>
        </List>
        <p>
          To browse a curated collection of notional machines used in programming education,
          check out <b><a href="https://notionalmachines.github.io/">notionalmachines.github.io</a></b>.
        </p>
      </Segment>
    </Container>
  </Layout>
)

export default NotionalMachinesPage

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/data/nms/*.md" } }
      sort: { fields: [frontmatter___id], order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          name
          shortDescription
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
