import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Image2 = ({ src, ...rest }) => {
  //console.log("### DEBUG ### Image2: ", src, rest);
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  //console.log("### data", data);

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );
  //console.log("### match", match);

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === 'svg' || !childImageSharp) {
    let result = <img src={publicURL} {...rest} />;
    //console.log("### result", result);
    return result;
  }

  let result = <Img fluid={childImageSharp.fluid} {...rest} />;
  //console.log("### result", result);
  return result;
};

Image2.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Image2;
